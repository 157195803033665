<template>
  <div class="vf-ai-voice">
    <div class="crumb-wapper container_media">
        <vf-breadcrumb :data="breadData"></vf-breadcrumb>
      </div>
    <div class="headline">
      <p class="title">
        <span>AI语音</span>
        <span>在线转换</span>
      </p>
      <p class="subhead">AI voice online conversion</p>
    </div>
    <!-- <img class="banner" :src="require('@/assets/image/ai-banner.png')" /> -->
    <div class="vf-ai-voice-content container_media">
      <!-- 文字转语音 -->
      <!-- <p class="title">AI语音在线转换</p> -->
      <div class="vf-ai-voice-content-operation">
        <div class="vf-ai-voice-content-operation-left">
          <!-- 输入文字 -->
          <div class="content">
            <el-input type="textarea" class="content-input" ref="input" v-model="tts.content" contenteditable
              placeholder="请输入需要转换的文字……" place>
            </el-input>
          </div>
          <!-- 本月下载次数 -->
          <div class="download-count">
            <i class="el-icon-warning-outline"></i>
            <p>会员用户每次点击【转换试听】，都会消耗一次当日下载次数，建议每条语音字数在200字以内</p>
          </div>
        </div>
        <!-- 选择文字转语音时的参数 -->
        <div class="vf-ai-voice-content-operation-right">
          <!-- 选择发音人 -->
          <div class="choose-voice">
            <div class="title rowShow">选择声音：</div>
            <div class="choose-voice-content">
              <div class="item" v-for="(item, index) in speakerList" :key="index">
                <div class="item-con">
                  <div :class="[
                    'item-person',
                    { 'item-person-active': item.vcn === tts.speaker }
                  ]" @click="handleChoosePerson(item.vcn)">
                    <img :src="item.img" />
                    <span>{{ item.desc }}</span>
                  </div>
                </div>

                <div class="audition-btn active" @click="handleChoosePerson(item.vcn)">
                  <AuditionBtn :item="item" :isPlaying="demo_url === item.demo_url"
                    @handleSelectInfo="handleSelectInfo" />
                </div>
              </div>
            </div>
            <!-- 播放选中的音频 -->
            <audio ref="audio" :src="demo_url" @ended="onEnded"></audio>
          </div>
          <!-- 选择参数 -->
          <div class="choose-params">
            <div class="choose-params-content">
              <div class="slider">
                <span>语速：</span>
                <el-slider class="slider-item" v-model="tts.speed" show-tooltip></el-slider>
              </div>
              <div class="slider">
                <span>音量：</span>
                <el-slider class="slider-item" v-model="tts.volume" show-tooltip></el-slider>
              </div>
              <div class="slider">
                <span>音高：</span>
                <el-slider class="slider-item" v-model="tts.pitch" show-tooltip></el-slider>
              </div>
            </div>
          </div>
          <!-- 背景音乐 -->
          <div class="choose-bgm">
            <div class="title">背景音乐：</div>
            <el-switch v-model="tts.bgm" @change="onChooseBgm" active-text="开" active-color="#0061F3"
              inactive-color="#EBEBEB">
            </el-switch>
            <!-- <el-radio v-model="tts.bgm" :label="1" @click.prevent.native="onChooseBgm"></el-radio> -->
          </div>
            <div class="btns-wrapper">
              <div>
                <div v-if="file && file !== ''" class="content-btn">
                  <div @click="playAiVoice">
                    <img :src="
                      require(`@/assets/svg/${playing && musicId === globalMusicId ? 'pause' : 'play'
                        }-icon-audio.svg`)
                    " />
                  </div>
                  <div @click="addToCar">
                    <img :src="require('@/assets/svg/car-theme.svg')" />
                  </div>
                </div>
              </div>
              <div class="btn">
                <span @click="toVoice">转换试听</span>
                <span @click="downloadVoice">下载语音</span>
              </div>
            </div>

        </div>
      </div>
    </div>
    <!-- 推荐视频 -->
    <!-- <recommendModel></recommendModel> -->
    <audio :src="file" ref="aiPlayer"></audio>
  </div>
</template>

<script>
import { fetchVoicePerson } from '@/api/ai'
import Buttons from '@/layout/mixins/Buttons'
import { getToken } from '@/utils/author'
import axios from 'axios'
import AuditionBtn from './components/AuditionBtn'
// import recommendModel from './components/recommendModel.vue';
import VfBreadcrumb from '@/components/VfBreadcrumb.vue'

const tips = ['请您输入要转换的文字内容', '您还没有进行转换']

export default {
  name: 'AiVoice',
  components: { AuditionBtn, VfBreadcrumb },
  data() {
    return {
      breadData: [
        {breadcrumbName: 'PlayList', path: '/'},
        {breadcrumbName: 'AI语音'}
      ],
      speakerList: [], // 发音人列表
      token: null,
      tts: {
        content: '', // 文字
        speaker: '', // 选中发音人标识
        speed: 50, // 语速 0-100正整数
        volume: 50, // 音量 0-100正整数
        pitch: 50, // 音高 0-100正整数
        bgm: false // 是否添加背景音乐
      },
      file: '', // 生成的音频文件
      demo_url: '', // 试听音频
      musicId: '', // 音乐id
      isPlay: false // 是否在播放AI语音
    }
  },
  computed: {
    playing() {
      return this.$store.state.music.isPlaying
    },
    globalMusicId() {
      return this.$store.state.music?.tmpMusicInfo?.object_id
    }
  },
  watch: {
    'tts.content'() {
      this.file = null
      this.musicId = null
    },
    'tts.speaker'() {
      this.file = null
      this.musicId = null
    },
    'tts.speed'() {
      this.file = null
      this.musicId = null
    },
    'tts.volume'() {
      this.file = null
      this.musicId = null
    },
    'tts.pitch'() {
      this.file = null
      this.musicId = null
    },
    playing(val) {
      if (val) {
        this.onEnded()
      }
    },
    'tts.bgm'() {
      this.file = null
      this.musicId = null
    }
  },
  created() {
    this.getPersonList()
    this.token = getToken()
    // console.log(this.token)
  },
  mixins: [Buttons],
  methods: {
    /**
     * 获取发音人列表
     */
    async getPersonList() {
      try {
        const { data } = await fetchVoicePerson()
        this.speakerList = data
        this.tts.speaker = this.speakerList[0].vcn
      } catch (e) {
        console.log(e)
      }
    },
    /**
     * 选择播放试听音频
     * 2022-04-18 16:48:54
     * @author SnowRock
     */
    handleSelectInfo(playing = true, demo_url) {
      if (demo_url) {
        this.demo_url = demo_url === this.demo_url ? this.demo_url : demo_url
      }

      this.demoAudionPlaying = playing
      this.$nextTick(() => {
        this.$refs.audio.currentTime = 0
        this.$refs.audio[playing ? 'play' : 'pause']()
      })
    },
    /**
     * 试听播放结束
     * 2022-04-18 16:53:00
     * @author SnowRock
     */
    onEnded() {
      this.demoAudionPlaying = false
      this.demo_url = ''
    },
    /**
     * 选择发音人
     * @param {String} vcn 发音人标识
     */
    handleChoosePerson(vcn) {
      this.tts.speaker = vcn
    },

    /**
     * 选择背景音乐
     */
    onChooseBgm(val) {
      this.tts.bgm = val // 实现选中/取消选中
    },
    /**
     * 转换试听
     */
    async toVoice() {
      if (!this.tts.content) {
        this.$message.warning(tips[0])
        return
      }

      // const result = await this.handleCheckedTimes(1)
      // if (!result) return

      const params = this.tts
      const token = getToken() // 获取token值

      try {
        axios({
          url: `${process.env.VUE_APP_BASE_URL}/fontend/AIVoice/toVoice`,
          method: 'post',
          headers: {
            token: token
          },
          data: {
            content: params.content,
            vcn: params.speaker,
            speed: params.speed,
            volume: params.volume,
            pitch: params.pitch,
            bgs: params.bgm ? 1 : 0
          }
        })
          .then((res) => {
            if (res.data.code !== 0) {
              if (res.data.code == -1) {
                this.$message.error(res.data.msg)
                // this.$router.push({path:'/package/page'});
                return
              }
              if (res.data.code == 5002) {
                const token = getToken()
                // 如果未登录用户试听则进入登录页面
                if (!token) {
                  this.$store.dispatch('user/openLogin')
                  return
                }

                if (!this.$store.state.user?.userInfo?.vip_info?.is_vip) {
                  // 如果试听30次后无会员再次点击播放跳转至开通会员列表
                  this.$router.push(`/package`)
                  return
                }
              }
              if (res.data.code == 2001 || res.data.code == 2002) {
                this.$store.dispatch('user/openLogin')
                return
              }
              return
            }
            this.file = res.data.data.voice_url
            this.musicId = res.data.data.music_id
          })
          .catch((error) => {
            console.log(error)
          })

        // const { data } = await tts({
        //   content: params.content,
        //   vcn: params.speaker,
        //   speed: params.speed,
        //   volume: params.volume,
        //   pitch: params.pitch,
        //   bgs: params.bgm
        // })
        // this.file = data.voice_url
        // this.musicId = data.music_id
        // console.log(data)
      } catch (e) {
        console.log(e)
      }
    },

    /**
     * 播放AI语音
     */
    playAiVoice() {
      if (!this.file) {
        this.$message.warning(tips[1])
      } else {
        // 如果播放其他的语音的话 直接进行播放当前的语音
        if (this.musicId !== this.globalMusicId) {
          this.handlePlayerMusic({
            music_id: this.musicId,
            object_id: this.musicId,
            type: 1, // AI语音
            labels: [],
            watermark_file: this.file,
            duration: this.$refs.aiPlayer.duration
          })
          return
        }
        // 如果当前音乐播放的是现有的语音条 则自动按播放暂停自动调整内容
        if (!this.playing) {
          this.handlePlayerMusic({
            object_id: this.musicId,
            type: 1, // AI语音
            labels: [],
            watermark_file: this.file,
            duration: this.$refs.aiPlayer.duration
          })
          return
        }
        // 默认暂停列表
        this.$store.dispatch('music/musicPause')
      }
    },

    /**
     * 下载语音
     */
    downloadVoice() {
      if (!this.file || !this.musicId) {
        this.$message.warning(tips[1])
      } else {
        this.handleDownload({ object_id: this.musicId, type: 1 }, 'single')
      }
    },

    /**
     * 添加至购物车
     */
    addToCar() {
      if (!this.file || !this.musicId) {
        this.$message.warning(tips[1])
      } else {
        this.handleAddGoodsToCart({
          object_id: this.musicId,
          type: 1
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vf-ai-voice {
  margin-top: 70px;
  @include center-middle-flex;
  @include flex-direction(column);
  .crumb-wapper{
    width: 100%;
    margin: 20px 0 20px;
  }
  .headline {
    margin: 30px auto 36px;
    text-align: center;
    font-family: PingFangSC-Medium, PingFang SC;

    .title {
      font-size: 36px;
      font-weight: 500;
      color: #333333;

      span {
        &:first-child {
          color: #e52a0d;
        }
      }
    }

    .subhead {
      font-size: 20px;
      font-weight: 400;
      color: #c3c3cd;
      line-height: 28px;
      letter-spacing: 1px;
      margin-top: 8px;
    }
  }

  .banner {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 37px;
    margin-left: 10px;
  }

  &-content {
    width: 100%;
    background: $base-color;
    margin-bottom: 70px;
    // padding: 0 $padding-lr;

    .title {
      @include font-bold($size: 18px, $color: rgba($font-theme-color, 0.8));
      line-height: 25px;
    }

    &-operation {
      display: flex;
      justify-content: space-between;
      border: 1px solid #dddddd;

      &-left {
        width: 38%;
        min-width: 440px;
        position: relative;

        .content {
          position: relative;
          height: 100%;

          .input[contenteditable]:empty:not(:focus):before {
            content: attr(placeholder);
            color: rgba($font-theme-color, 0.36);
          }

          /deep/ {
            .content-input {
              @include size(100%, 92%);
              overflow-y: scroll;
              // border-radius: 12px;
              opacity: 0.6;
              @include font-bold($size: 20px, $color: #000);
              line-height: 28px;
              outline: none;
              padding-right: 0;

              textarea {
                width: 100%;
                height: 100%;
                margin: 0;
                border: none;
                outline: none;
                // background: rgba(178, 178, 178, 0.2);
                // border-radius: 12px;
                padding: 20px;
                color: #333;
              }

              .el-textarea__inner {
                font-size: 14px;

                &::placeholder {
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: rgba(0, 0, 0, 0.5);
                }
              }
            }
          }
        }

        .download-count {
          position: absolute;
          @include font-normal($size: 12px, $color: rgba(51, 51, 51, 0.48));
          bottom: 15px;
          left: 25px;
          display: flex;
          align-items: flex-start;

          i {
            font-size: 14px;
            color: #ff8e14;
            margin-right: 6px;
          }
          p {
            line-height: 14px;
          }
        }
      }

      &-right {
        width: 62%;
        padding: 24px 0 24px 24px;
        background: #fafafa;

        .title {
          @include font-bold($size: 16px, $color: rgba(0, 0, 0, 0.85));
          margin-right: 12px;
          min-width: 70px;
        }

        .rowShow {
          display: block;
          width: 100%;
        }

        .choose-voice {
          width: 97.5%;

          &-content {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fill, 82px);
            grid-gap: 0 1px;
            justify-content: space-between;
            background: #fff;
            padding: 20px 30px 0px 36px;
            box-sizing: border-box;
            margin-top: 16px;

            .item {
              width: 70px;
              text-align: center;
              margin-bottom: 20px;
              .item-con {
                width: 82px
              }

              &-person {
                @include center-middle-flex;
                flex-direction: column;
                @include size(70px, 80px);
                border-radius: 8px;
                background: rgba(178, 178, 178, 0.1);
                cursor: pointer;

                img {
                  @include square(40px);
                }

                span {
                  @include font-normal($size: 14px,
                    $color: rgba(51, 51, 51, 0.5));
                  line-height: 20px;
                  margin-top: 2px;
                }
              }

              &-person-active {
                background: rgba($theme-color, 0.1);
              }

              &-point {
                @include square(6px);
                border-radius: 50%;
                margin-top: 8px;
              }

              &-point-active {
                background: $theme-color;
              }
            }
          }
        }

        .choose-params {
          margin-top: 24px;
          width: 100%;

          &-content {

            .slider {
              display: flex;
              align-items: center;
              margin-bottom: 10px;

              span {
                @include font-normal($size: 14px, $color: #333333);
                margin-right: 12px;
                line-height: 20px;
                min-width: 70px;
                font-weight: 600;
              }

              &-item {
                width: 85%;
              }

              /deep/ .el-slider__runway {
                height: 4px;
                background: #EBEBEB;

                .el-slider__bar {
                  height: 100%;
                  background: #E52A0D;
                }

                .el-slider__button-wrapper {
                  width: 36px;
                  height: 36px;
                  z-index: 1;
                }

                .el-slider__button {
                  @include size(14px, 14px);
                  border-radius: 50%;
                  background: #E52A0D;
                  border: none;
                }
              }
            }
          }
        }

        .choose-bgm {
          @include center-middle-flex($justify: flex-start);
          margin-top: 24px;

          /deep/ {
            .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
              box-shadow: none; // 去掉选中时的光圈
            }

            .el-radio {
              .el-radio__label {
                color: transparent;
              }

              .el-radio__inner {
                @include square(24px);
                border: 2px solid rgba(51, 51, 51, 0.56);
                outline: none;
              }

              &.is-checked {
                .el-radio__inner {
                  background-color: #fff;
                  border-color: rgba(51, 51, 51, 0.56);
                }
              }

              .el-radio__inner::after {
                @include square(12px);
                background: rgba(51, 51, 51, 0.56);
              }

              .el-radio__inner {
                &:hover {
                  border-color: rgba(51, 51, 51, 0.56);
                }
              }
            }

          }
        }
        .content-btn {
          @include center-middle-flex;
          cursor: pointer;

          div {
            @include square(56px);
            @include center-middle-flex;
            background: $base-color;
            box-shadow: 0px 0px 12px 0px rgba(33, 48, 83, 0.07);
            border-radius: 50%;

            img {
              @include square(28px);
            }
          }

          div:nth-of-type(1) {
            margin-right: 12px;
          }
        }
        .btns-wrapper {
          @include center-middle-flex($justify: space-between);
          margin-top: 22px;
          margin-right: 36px;

          span {
            @include size(120px, 40px);
            @include center-middle-flex;
            border-radius: 2px;
            border: 1px solid #E52A0D;
            @include font-bold($size: 18px, $color: #E52A0D);
            cursor: pointer;
          }

          span:nth-last-of-type(1) {
            background: #E52A0D;
            color: #fff;
            margin-left: 16px;
          }
        }
        .btn{
          @include center-middle-flex($justify: center);
        }

        .audition-btn {
          margin-top: 8px;

          line-height: 22px;
          height: 24px;
          color: #E52A0D;
          font-size: 14px;
          border: 1px solid #E52A0D;
          border-radius: 5px;
          box-sizing: border-box;
          opacity: 0;
          display: none;
          cursor: pointer;

          &.active {
            opacity: 1;
            display: inline-block;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .container_media {
    .vf-ai-voice-content-operation-right {
      .choose-voice-content {
        display: flex;
      }
    }
  }
}
</style>
