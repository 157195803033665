<template>
  <span class='audiobtn' @click="isPlaying && playing ? handlePause() : handlePlay()">
    <label>试听</label>
    <ul v-if="isPlaying && playing" class="pause">
      <li class="beat"></li>
      <li class="beat"></li>
      <li class="beat"></li>
      <li class="beat"></li>
    </ul>
  </span>
</template>
<script>
  export default {
    name: "AuditionBtn",
    props: {
      item: {
        type: Object,
        default() {
          return {}
        }
      },
      isPlaying: {
        type: Boolean,
        default() {
          return false
        }
      }
    },
    data() {
      return {
        playing: false
      }
    },
    methods: {
      handlePlay() {
        this.$store.dispatch('music/pause')
        this.playing = true
        this.$emit('handleSelectInfo', true, this.item.demo_url)
      },
      handlePause() {
        this.playing = false
        this.$emit('handleSelectInfo', false)
      }
    }
  }
</script>

<style lang="scss" scoped>
.audiobtn{
  //width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 10px;
  box-sizing: border-box;
  label{
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #E52A0D;
  }
}
.pause{
  flex: 1;
  //width: 10px;
  height: 8px;
  overflow: hidden;
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: 2px;
  .beat{
    width: 1.5px;
    height: 8px;
    background: #E52A0D;
    -webkit-animation: beat-move .5s ease-out infinite alternate;
    &:first-of-type{
      animation-delay: .3s;
    }
    &:nth-of-type(2){
      animation-delay: 0s;
    }
    &:nth-of-type(3){
      animation-delay: .375s;
    }
    &:nth-of-type(4){
      animation-delay: .25s;
    }
  }
  @keyframes beat-move {
    0%{
      -webkit-transform: translateY(100%);
    }
    25%{
      -webkit-transform: translateY(75%);
    }
    50%{
      -webkit-transform: translateY(50%);
    }
    75%{
      -webkit-transform: translateY(25%);
    }
    100%{
      -webkit-transform: translateY(0);
    }
  }
}
</style>
