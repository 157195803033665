/*
 * @Author: songyajuan
 * @Date: 2021-12-29 11:37:34
 * @Description: AI相关请求
 * @Last Modified by: songyajuan
 * @Last Modified time: 2021-12-29 13:19:46
 */

import request from '@/utils/request'

/**
 * 发音人列表
 */
export function fetchVoicePerson() {
  return request.post('/fontend/AIVoice/voicePerson')
}

/**
 * 文字转语音
 */
export function tts(params) {
  return request.post('/fontend/AIVoice/toVoice', params, {})
}
