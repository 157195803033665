var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vf-ai-voice"},[_c('div',{staticClass:"crumb-wapper container_media"},[_c('vf-breadcrumb',{attrs:{"data":_vm.breadData}})],1),_vm._m(0),_c('div',{staticClass:"vf-ai-voice-content container_media"},[_c('div',{staticClass:"vf-ai-voice-content-operation"},[_c('div',{staticClass:"vf-ai-voice-content-operation-left"},[_c('div',{staticClass:"content"},[_c('el-input',{ref:"input",staticClass:"content-input",attrs:{"type":"textarea","contenteditable":"","placeholder":"请输入需要转换的文字……","place":""},model:{value:(_vm.tts.content),callback:function ($$v) {_vm.$set(_vm.tts, "content", $$v)},expression:"tts.content"}})],1),_vm._m(1)]),_c('div',{staticClass:"vf-ai-voice-content-operation-right"},[_c('div',{staticClass:"choose-voice"},[_c('div',{staticClass:"title rowShow"},[_vm._v("选择声音：")]),_c('div',{staticClass:"choose-voice-content"},_vm._l((_vm.speakerList),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"item-con"},[_c('div',{class:[
                  'item-person',
                  { 'item-person-active': item.vcn === _vm.tts.speaker }
                ],on:{"click":function($event){return _vm.handleChoosePerson(item.vcn)}}},[_c('img',{attrs:{"src":item.img}}),_c('span',[_vm._v(_vm._s(item.desc))])])]),_c('div',{staticClass:"audition-btn active",on:{"click":function($event){return _vm.handleChoosePerson(item.vcn)}}},[_c('AuditionBtn',{attrs:{"item":item,"isPlaying":_vm.demo_url === item.demo_url},on:{"handleSelectInfo":_vm.handleSelectInfo}})],1)])}),0),_c('audio',{ref:"audio",attrs:{"src":_vm.demo_url},on:{"ended":_vm.onEnded}})]),_c('div',{staticClass:"choose-params"},[_c('div',{staticClass:"choose-params-content"},[_c('div',{staticClass:"slider"},[_c('span',[_vm._v("语速：")]),_c('el-slider',{staticClass:"slider-item",attrs:{"show-tooltip":""},model:{value:(_vm.tts.speed),callback:function ($$v) {_vm.$set(_vm.tts, "speed", $$v)},expression:"tts.speed"}})],1),_c('div',{staticClass:"slider"},[_c('span',[_vm._v("音量：")]),_c('el-slider',{staticClass:"slider-item",attrs:{"show-tooltip":""},model:{value:(_vm.tts.volume),callback:function ($$v) {_vm.$set(_vm.tts, "volume", $$v)},expression:"tts.volume"}})],1),_c('div',{staticClass:"slider"},[_c('span',[_vm._v("音高：")]),_c('el-slider',{staticClass:"slider-item",attrs:{"show-tooltip":""},model:{value:(_vm.tts.pitch),callback:function ($$v) {_vm.$set(_vm.tts, "pitch", $$v)},expression:"tts.pitch"}})],1)])]),_c('div',{staticClass:"choose-bgm"},[_c('div',{staticClass:"title"},[_vm._v("背景音乐：")]),_c('el-switch',{attrs:{"active-text":"开","active-color":"#0061F3","inactive-color":"#EBEBEB"},on:{"change":_vm.onChooseBgm},model:{value:(_vm.tts.bgm),callback:function ($$v) {_vm.$set(_vm.tts, "bgm", $$v)},expression:"tts.bgm"}})],1),_c('div',{staticClass:"btns-wrapper"},[_c('div',[(_vm.file && _vm.file !== '')?_c('div',{staticClass:"content-btn"},[_c('div',{on:{"click":_vm.playAiVoice}},[_c('img',{attrs:{"src":require(`@/assets/svg/${_vm.playing && _vm.musicId === _vm.globalMusicId ? 'pause' : 'play'
                      }-icon-audio.svg`)}})]),_c('div',{on:{"click":_vm.addToCar}},[_c('img',{attrs:{"src":require('@/assets/svg/car-theme.svg')}})])]):_vm._e()]),_c('div',{staticClass:"btn"},[_c('span',{on:{"click":_vm.toVoice}},[_vm._v("转换试听")]),_c('span',{on:{"click":_vm.downloadVoice}},[_vm._v("下载语音")])])])])])]),_c('audio',{ref:"aiPlayer",attrs:{"src":_vm.file}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"headline"},[_c('p',{staticClass:"title"},[_c('span',[_vm._v("AI语音")]),_c('span',[_vm._v("在线转换")])]),_c('p',{staticClass:"subhead"},[_vm._v("AI voice online conversion")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-count"},[_c('i',{staticClass:"el-icon-warning-outline"}),_c('p',[_vm._v("会员用户每次点击【转换试听】，都会消耗一次当日下载次数，建议每条语音字数在200字以内")])])
}]

export { render, staticRenderFns }